import React from "react";
import { NavLink } from "react-router-dom";
const TextEditorList = () => {
  return (
    <>
      <li>
        <NavLink to="/tools/comparetext">Compare Text</NavLink>
      </li>

      <li>
        <NavLink to="/tools/removeextraspace">Remove Extraspace</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-line-break">Remove Line Break</NavLink>
      </li>

      <li>
        <NavLink to="/tools/add-line-break">Add Line Break</NavLink>
      </li>

      <li>
        <NavLink to="/tools/remove-duplicate-lines">Remove Duplicate Lines</NavLink>
      </li>

      <li>
        <NavLink to="/tools/characterreplace">Replace Character</NavLink>
      </li>

      <li>
        <NavLink to="/tools/removecharacter">Remove Character</NavLink>
      </li>

      <li>
        <NavLink to="/tools/textcounter">Text Counter</NavLink>
      </li>

      <li>
        <NavLink to="/tools/numberextractor">Number Extractor</NavLink>
      </li>

      <li>
        <NavLink to="/tools/phonenoextractor">Phone No. Extractor</NavLink>
      </li>

      <li>
        <NavLink to="/tools/texttransform">Text transform</NavLink>
      </li>

      <li>
        <NavLink to="/tools/paragraph-to-lines">Paragraphs To Single Line</NavLink>
      </li>

      <li>
        <NavLink to="/tools/linebreaks-to-paragraph">Line Breaks to Paragraphs</NavLink>
      </li>

    </>
  );
};

export default TextEditorList;
