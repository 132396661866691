import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  Home,
  AboutUs,
  BlogDetail,
  Blogs,
  GuestPost,
  ContactUs,
  Customer,
  Design,
  DigitalMarketing,
  Frontend,
  WebDeveloment,
  Animations,
  Product,
  Team,
  UserProfile,
} from "../views";

import WritePost from "../views/writepost/WritePost";

// Array of routes with their respective components
const routes = [
  { path: "/write-post", name: "Write Post", component: WritePost },
  { path: "/", name: "Home", component: Home },
  { path: "about", name: "About us", component: AboutUs },
  { path: "blogs", name: "Blogs", component: Blogs },
  { path: "blog-detail/:slug", name: "Blog Detail", component: BlogDetail },
  { path: "guest-post", name: "Guest Post", component: GuestPost },
  { path: "contact-us", name: "Contact Us", component: ContactUs },
  { path: "customers", name: "Customers", component: Customer },
  { path: "product", name: "Products", component: Product },
  { path: "services/web-design", name: "Design", component: Design },
  { path: "services/frontend-development", name: "Frontend Development", component: Frontend },
  {
    path: "services/digital-marketing",
    name: "Digital Marketing",
    component: DigitalMarketing,
  },
  {
    path: "services/web-development",
    name: "Web Development",
    component: WebDeveloment,
  },
  {
    path: "services/2d-animations",
    name: "2D Animations",
    component: Animations,
  },
  { path: "team", name: "Team", component: Team },
  { path: "user-profile", name: "User Profile", component: UserProfile },
];

const AppContent = () => {
  const location = useLocation();

  return (
    <>
      <div className="main_panel w-100">
        <div className="content_wrapper ms-0">
          <Routes key={location.pathname} location={location}>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                name={route.name}
                element={<route.component />}
              />
            ))}
            {/* 404 or default route */}
            <Route path="*" element={<Navigate to="notfound" replace />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export default AppContent;
