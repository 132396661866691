import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation, NavLink } from "react-router-dom";
import "../sass/sidebar.scss";
import SidebarToolPages from "./SidebarToolPages";
import SidebarOtherPages from "./SidebarOtherPages";

const AppSideBar = (props) => {
  // PAGES
  const [homeActive, homeSetActive] = useState(false);
  const [productActive, productSetActive] = useState(false);

  const [serviceActive, serviceSetActive] = useState(false);
  //SERVICE - SUB PAGE
  const [designActive, designSetActive] = useState(false);
  const [frontActive, frontendSetActive] = useState(false);
  const [seoActive, seoSetActive] = useState(false);
  const [webDevActive, webDevSetActive] = useState(false);

  const [guestPostActive, guestPostSetActive] = useState(false);
  const [contactActive, contactSetActive] = useState(false);
  const [aboutActive, aboutSetActive] = useState(false);
  const [blogActive, blogSetActive] = useState(false);
  const [customerActive, customerSetActive] = useState(false);

  const [converterActive, converterSetActive] = useState(false);
  const [textEditorActive, textEditorSetActive] = useState(false);
  const [generatorsActive, generatorsSetActive] = useState(false);
  const [calculatorActive, calculatorSetActive] = useState(false);
  // const [downloaderActive, downloaderSetActive] = useState(false);
  const [imageEditorActive, imageEditorSetActive] = useState(false);
  const { itemClick } = props;

  // This hook returns the current location object. In this case we are geting the loction of '/' and '/about'
  let location = useLocation();

  const handleClick = useMemo(
    () => ({
      converterClick: (event) => {
        event.preventDefault();

        converterSetActive(!converterActive);
        textEditorSetActive(false);
        generatorsSetActive(false);
        calculatorSetActive(false);
        // downloaderSetActive(false);
        imageEditorSetActive(false);
      },
      editorClick: (event) => {
        event.preventDefault();

        textEditorSetActive(!textEditorActive);
        generatorsSetActive(false);
        converterSetActive(false);
        calculatorSetActive(false);
        //  downloaderSetActive(false);
        imageEditorSetActive(false);
      },
      generatorClick: (event) => {
        event.preventDefault();

        generatorsSetActive(!generatorsActive);
        converterSetActive(false);
        textEditorSetActive(false);
        calculatorSetActive(false);
        // downloaderSetActive(false);
        imageEditorSetActive(false);
      },
      calculatorClick: (event) => {
        event.preventDefault();

        calculatorSetActive(!calculatorActive);
        converterSetActive(false);
        textEditorSetActive(false);
        generatorsSetActive(false);
        imageEditorSetActive(false);
      },
      //   downloaderClick: (event) => {
      //     event.preventDefault();
      //  //   downloaderSetActive(!downloaderActive);
      //     converterSetActive(false);
      //     textEditorSetActive(false);
      //     generatorsSetActive(false);
      //   //  imageEditorSetActive(false);
      //   },
      imageEditorClick: (event) => {
        event.preventDefault();

        imageEditorSetActive(!imageEditorActive);
        converterSetActive(false);
        textEditorSetActive(false);
        generatorsSetActive(false);
        calculatorSetActive(false);
        // downloaderSetActive(false);
      },
      /// PAGES
      homeClick: (event) => {
        // event.preventDefault();
        homeSetActive(!homeActive);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
      },
      productClick: (event) => {
       // event.preventDefault();
        serviceSetActive(false);
        homeSetActive(false);
        productSetActive(!productActive);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
      },
      serviceClick: (event) => {
        event.preventDefault();

        serviceSetActive(!serviceActive);
        homeSetActive(false);
        productSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
      },

      designClick: (event) => {
        event.preventDefault();
        designSetActive(!designActive);
        frontendSetActive(false);
        seoSetActive(false);
        webDevSetActive(false);
      },

      frontEndClick: (event) => {
        event.preventDefault();
        frontendSetActive(!frontActive);
        designSetActive(false);
        seoSetActive(false);
        webDevSetActive(false);
      },

      seoClick: (event) => {
        event.preventDefault();
        seoSetActive(!seoActive);
        designSetActive(false);
        frontendSetActive(false);
        webDevSetActive(false);
      },

      webDevClick: (event) => {
        event.preventDefault();
        webDevSetActive(!webDevActive);
        designSetActive(false);
        frontendSetActive(false);
        seoSetActive(false);
      },

      guestPostClick: (event) => {
        // event.preventDefault();
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(!guestPostActive);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
      },
      contactClick: (event) => {
        //     event.preventDefault();
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(!contactActive);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
      },
      aboutClick: (event) => {
        //    event.preventDefault();
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(!aboutActive);
        blogSetActive(false);
        customerSetActive(false);
      },
      blogClick: (event) => {
        //   event.preventDefault();
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(!blogActive);
        customerSetActive(false);
      },
      customerClick: (event) => {
        // event.preventDefault();
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(!customerActive);
      },
    }),
    [
      converterActive,
      textEditorActive,
      generatorsActive,
      calculatorActive,
      imageEditorActive,
      //  downloaderActive,

      // PAGES
      homeActive,
      productActive,

      serviceActive,
      //sub menu
      designActive,
      frontActive,
      seoActive,
      webDevActive,

      guestPostActive,
      contactActive,
      aboutActive,
      blogActive,
      customerActive,
    ]
  );

  const closeSidebar = () => {
    itemClick(false);
  };

  useMemo(() => {
    switch (location.pathname) {
      case "/tools/imagecompress":
      case "/tools/jpgtopng":
      case "/tools/pngtogif":
      case "/tools/mp4tomp3":
      case "/tools/mp3tomp4":
      case "/tools/wordtopdf":
      case "/tools/pdftojpg":
      case "/tools/pdftopng":
      case "/tools/pdftoword":
      case "/tools/pngtojpg":
      case "/tools/pngtopdf":
      case "/tools/jpgtogif":
      case "/tools/image-resizer":
      case "/tools/bgremover":
        converterSetActive(true);
        return "converter";

      case "/tools/comparetext":
      case "/tools/phonenoextractor":
      case "/tools/textcounter":
      case "/tools/removeextraspace":
      case "/tools/characterreplace":
      case "/tools/numberextractor":
      case "/tools/removecharacter":
      case "/tools/remove-line-break":
      case "/tools/remove-duplicate-lines":
      case "/tools/texttransform":
      case "/tools/add-line-break":
      case "/tools/paragraph-to-lines":
      case "/tools/linebreaks-to-paragraph":
        textEditorSetActive(true);
        return "textEditor";

      case "/tools/qrcodegenerator":
      case "/tools/privacygenerator":
      case "/tools/colormixer":
      case "/tools/contractpolicy":
      case "/tools/businessnamegenerator":
      case "/tools/gradientgenerator":
      case "/tools/htmlcolorgenerator":
      case "/tools/nicknamesgenerator":
      case "/tools/numbergenerator":
      case "/tools/passwordgenerator":
      case "/tools/textgenerator":
      case "/tools/webfontgenerator":
        generatorsSetActive(true);
        return "generators";

      case "/tools/agecalculator":
      case "/tools/percentage-calculator":
      case "/tools/average-calculator":
      case "/tools/salestax-calculator":
      case "/tools/margin-calculator":
      case "/tools/gst-calculator":
      case "/tools/loan-calculator":
      case "/tools/cpm-calculator":
      case "/tools/discount-calculator":
      case "/tools/paypalfee-calculator":
        calculatorSetActive(true);
        return "calculator";

      case "/tools/imageeditor":
        imageEditorSetActive(true);
        return "imageeditor";

      case "/":
        homeSetActive(true);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        return "home";

      case "/product":
        productSetActive(true);
        homeSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
        serviceSetActive(false);
        return "product";

      // case "/services/web-design/ui-ux":
      //   serviceSetActive(true);
      //   return "services";

      case "/services/web-design/graphic-design":
      case "/services/web-design/ui-ux":
        serviceSetActive(true);
        designSetActive(true);
        frontendSetActive(false);
        seoSetActive(false);
        webDevSetActive(false);
        return "design";

      case "/services/frontend/html":
      case "/services/frontend/wordpress":
      case "/services/frontend/reactjs":
        serviceSetActive(true);
        frontendSetActive(true);
        designSetActive(false);
        seoSetActive(false);
        webDevSetActive(false);
        return "frontend";

      case "/services/digital-marketing/seo/on-page-seo":
      case "/services/digital-marketing/seo/off-page-seo":
        serviceSetActive(true);
        seoSetActive(true);
        frontendSetActive(false);
        designSetActive(false);
        webDevSetActive(false);
        return "digital-marketing";

      case "/services/web-development/enterprise-web-app":
      case "/services/web-development/web-portal":
      case "/services/web-development/healthcare-web-app":
        serviceSetActive(true);
        webDevSetActive(true);
        frontendSetActive(false);
        designSetActive(false);
        seoSetActive(false);
        return "Web Development";

      case "/guest-post":
        guestPostSetActive(true);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);

        return "guest-post";

      case "/contact-us":
        contactSetActive(true);
        aboutSetActive(false);
        blogSetActive(false);
        customerSetActive(false);
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);

        return "contact-us";

      case "/about":
        aboutSetActive(true);
        blogSetActive(false);
        customerSetActive(false);
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        return "about";

      case "/blogs":
        blogSetActive(true);
        customerSetActive(false);
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        return "blogs";

      case "/customers":
        customerSetActive(true);
        homeSetActive(false);
        productSetActive(false);
        serviceSetActive(false);
        guestPostSetActive(false);
        contactSetActive(false);
        aboutSetActive(false);
        blogSetActive(false);

        return "customers";

      default:
        return null;
    }
  }, [location.pathname]);

  return (
    <>
      <div className="sidenav cstm_scrollbar">
        {props.text && props.text === "ToolMenu" ? (
          <>
            <SidebarToolPages
              handleClick={handleClick}
              closeSidebar={closeSidebar}
              converterActive={converterActive}
              textEditorActive={textEditorActive}
              generatorsActive={generatorsActive}
              calculatorActive={calculatorActive}
              imageEditorActive={imageEditorActive}
            />
          </>
        ) : props.text && props.text === "pageMenu" ? (
          <>
            <SidebarOtherPages
              handleClick={handleClick}
              closeSidebar={closeSidebar}
              homeActive={homeActive}
              productActive={productActive}
              serviceActive={serviceActive}
              designActive={designActive}
              frontActive={frontActive}
              seoActive={seoActive}
              webDevActive={webDevActive}
              guestPostActive={guestPostActive}
              contactActive={contactActive}
              aboutActive={aboutActive}
              blogActive={blogActive}
              customerActive={customerActive}
              converterActive={converterActive}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default AppSideBar;
