import React from "react";
import { NavLink } from "react-router-dom";
const GeneratorsList = () => {

  return (
    <>
      <li>
        <NavLink to="/tools/contractpolicy">Contract Policy Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/qrcodegenerator">QR Code Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/passwordgenerator">Password Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/gradientgenerator">Gradient Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/textgenerator">Text Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/htmlcolorgenerator">HTML Color Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/colormixer">Color Mixer</NavLink>
      </li>

      <li>
        <NavLink to="/tools/numbergenerator">Number Generator</NavLink>
      </li>

      <li>
        <NavLink to="/tools/privacygenerator"> Privacy Generator</NavLink>
      </li>
    </>
  );
};

export default GeneratorsList;
