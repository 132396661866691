import { toast } from "react-toastify";
import {
    CREATE_COMMENT,
    GET_COMMENT_BYPOST_ID,
    CREATE_REPLY,
    GET_REPLIES_BYPOST_ID
  } from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
const api = new APIClient();


const createComment = async (obj) => {
     const id = toast.loading("Creating Comment...", { autoClose: false });
    try {
      const res = await api.create(
        `${process.env.REACT_APP_USER_URL}${CREATE_COMMENT}`,
        obj
      );
  
      if (res.status) {
        toast.update(id, {
          render: res.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(id, {
          render: res.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return res;
    } catch (error) {
      toast.update(id, {
        render: error,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };


  const AllCommentsByPostId = async (obj) => {
    try {
      const res = await api.get(
        `${process.env.REACT_APP_USER_URL}${GET_COMMENT_BYPOST_ID}?postid=${obj}`
      );
  
      if (res.status) {
      } else {
        // toast.error(res.message, { theme: "colored" });
        // toast.error(res, { theme: "colored" });
      }
      return res;
    } catch (error) {
      toast.error(error, { theme: "colored" });
    }
  };

  const createReply = async (obj) => {
   const id = toast.loading("Checking reply ...", { autoClose: false });
   try {
     const res = await api.create(
       `${process.env.REACT_APP_USER_URL}${CREATE_REPLY}`,
       obj
     );
 
     if (res.status) {
       toast.update(id, {
         render: res.message,
         type: "success",
         isLoading: false,
         autoClose: 3000,
       });
     } else {
       toast.update(id, {
         render: res.message,
         type: "error",
         isLoading: false,
         autoClose: 3000,
       });
     }
     return res;
   } catch (error) {
     toast.update(id, {
       render: error,
       type: "error",
       isLoading: false,
       autoClose: 3000,
     });
   }
 };

 const AllRepliesByPostId = async (obj) => {
  try {
    const res = await api.get(
      `${process.env.REACT_APP_USER_URL}${GET_REPLIES_BYPOST_ID}?postid=${obj}`
    );

    if (res.status) {
    } else {
      // toast.error(res.message, { theme: "colored" });
      // toast.error(res, { theme: "colored" });
    }
    return res;
  } catch (error) {
    toast.error(error, { theme: "colored" });
  }
};


  export const services = {
   
    createComment,
    AllCommentsByPostId,
    createReply,
    AllRepliesByPostId
  
  };