import React, { useEffect,  } from "react";
import { Navigate,  } from "react-router-dom";
import { setAuthorization } from "../../helpers/api_helper";
import { useDispatch,  } from "react-redux";
import MetaData from "../../components/MetaData";
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
//formik
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "react-quill/dist/quill.bubble.css"; // Import Quill styles
import ImageOne from "./ImageOne";
import ImageTwo from "./ImageTwo";
import ImageThree from "./ImageThree";
import { useProfile } from "../../components/Hooks/UserHooks";
import { ToastContainer } from "react-toastify";
import { createNewPost } from "../../slices/thunks";

const sortbycategory = [
  { label: "Technology", value: "Technology" },
  { label: "Shopping", value: "Shopping" },
  { label: "Business", value: "Business" },
  { label: "Health", value: "Health" },
  { label: "Fashion", value: "Fashion" },
  { label: "Travel", value: "Travel" },
  { label: "Lifestyle", value: "Lifestyle" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Website", value: "Website" },
  { label: "Online Marketing", value: "Online Marketing" },
];

const WritePost = () => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();

  useEffect(() => {
    if (userProfile && !loading && token) {
      setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      //  dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: "",
      category: "",
      imgOne: "",
      descriptionOne: "",
      imgTwo: "",
      descriptionTwo: "",
      imgThree: "",
      descriptionThree: "",
      status: "Pending",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Title"),
      category: Yup.string().required("Please Select Category"),
      imgOne: Yup.string().required("Please upload an image"),
      descriptionOne: Yup.string()
        .required("Please Enter Description")
        .test(
          "word-count",
          "Description must be at most 500 words",
          (value) => {
            if (!value) return true; // If no value provided, it's considered valid
            const wordCount = value.trim().split(/\s+/).length;
            return wordCount <= 500;
          }
        ),
      descriptionTwo: Yup.string().test(
        "word-count",
        "Description must be at most 500 words",
        (value) => {
          if (!value) return true; // If no value provided, it's considered valid
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 500;
        }
      ),
      descriptionThree: Yup.string().test(
        "word-count",
        "Description must be at most 500 words",
        (value) => {
          if (!value) return true; // If no value provided, it's considered valid
          const wordCount = value.trim().split(/\s+/).length;
          return wordCount <= 500;
        }
      ),
    }),
    onSubmit: (values) => {
      // console.log(values);
      const newPost = {
        id: 0,
        title: values["title"],
        category: values["category"],
        imgOne: values["imgOne"],
        descriptionOne: values["descriptionOne"],
        imgTwo: values["imgTwo"],
        descriptionTwo: values["descriptionTwo"],
        imgThree: values["imgThree"],
        descriptionThree: values["descriptionThree"],
        status: "Pending",
      };

      dispatch(createNewPost(newPost));
      validation.resetForm();
    },
  });

  const handleChangeOne = (html) => {
    // setEditorHtml(html);
    validation.setFieldValue("descriptionOne", html);
  };

  const handleChangeTwo = (html) => {
    // setEditorHtml(html);
    validation.setFieldValue("descriptionTwo", html);
  };

  const handleChangeThree = (html) => {
    // setEditorHtml(html);
    validation.setFieldValue("descriptionThree", html);
  };

  const modules = {
    toolbar: [
      [{ header: [2, 3, 4, false] }], // Removing h1 by setting it to false
      ["bold", "italic", "underline", "link"],
      [{ list: "ordered" }, { list: "bullet" }],
      // ['image', 'video'],
      ["clean"],
      // ["undo", "redo"],
    ],
  };

  if (!userProfile && loading && !token) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return (
    <>
      <MetaData
        metaTitle={"Write-post"}
        metaDescription={"Softorithm write your post"}
      />
      <div className="page_body">
        <ToastContainer closeButton={false} />
        <div className="convert_tool_wrapper">
          <div className="pg_title">
            <h2>Write Your Post Here</h2>
          </div>

          <Row className=" justify-content-center ">
            <Col lg={7}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="product-title-input"
                            placeholder="Enter Title"
                            name="title"
                            value={validation.values.title || ""}
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            invalid={
                              validation.errors.title &&
                              validation.touched.title
                                ? true
                                : false
                            }
                          />
                          {validation.errors.title &&
                          validation.touched.title ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Category
                          </Label>
                          <Select
                            value={sortbycategory?.find(
                              (option) =>
                                option.value === validation.values.category
                            )}
                            onChange={(e, selected) => {
                              validation.setFieldValue("category", e.value);
                            }}
                            className={`mb-0 ${
                              validation.touched.category &&
                              validation.errors.category
                                ? "is-invalid"
                                : ""
                            }`}
                            options={sortbycategory}
                            id="taginput-choices"
                          ></Select>
                          {validation.touched.category &&
                          validation.errors.category ? (
                            <FormFeedback type="invalid">
                              {validation.errors.category}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Image One
                          </Label>
                          <ImageOne validation={validation} />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Description</Label>

                          <ReactQuill
                            theme={"snow"} // Specify the theme ('snow' is one of the available themes)
                            placeholder="Write something awesome..."
                            value={validation.values.descriptionOne}
                            onChange={handleChangeOne}
                            modules={modules}
                          />
                          {validation.errors.descriptionOne &&
                            validation.touched.descriptionOne && ( // Check if there's an error for imageOne
                              <div className="text-danger">
                                {validation.errors.descriptionOne}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Image Two
                          </Label>
                          <ImageTwo validation={validation} />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Description Two</Label>

                          <ReactQuill
                            theme={"snow"} // Specify the theme ('snow' is one of the available themes)
                            placeholder="Write something awesome..."
                            value={validation.values.descriptionTwo}
                            onChange={handleChangeTwo}
                            modules={modules}
                          />
                          {validation.errors.descriptionTwo &&
                            validation.touched.descriptionTwo && ( // Check if there's an error for imageOne
                              <div className="text-danger">
                                {validation.errors.descriptionTwo}
                              </div>
                            )}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Image Three
                          </Label>
                          <ImageThree validation={validation} />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label>Description Three</Label>

                          <ReactQuill
                            theme={"snow"} // Specify the theme ('snow' is one of the available themes)
                            placeholder="Write something awesome..."
                            value={validation.values.descriptionThree}
                            onChange={handleChangeThree}
                            modules={modules}
                          />
                          {validation.errors.descriptionThree &&
                            validation.touched.descriptionThree && ( // Check if there's an error for imageOne
                              <div className="text-danger">
                                {validation.errors.descriptionThree}
                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <div className="text-end mb-3 mt-3">
                  <button type="submit" className="btn btn-success  ">
                    Publish
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default WritePost;
