import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Collapse, CardBody } from "reactstrap";


// import AnimatePage from "../../components/AnimatePage";
import { about_feature_img, yt_pst_img } from "../../images";
import "../sass/about.scss";
import TextSlicer from "../../components/TextSlicer";
import MetaData from "../../components/MetaData";
const description = `Softorithm is a cutting-edge digital agency dedicated to simplifying the digital trajectory for businesses. Our comprehensive range of services includes graphic design, UI/UX design, frontend development, WordPress development, software development, digital marketing, SEO strategies to boost your visibility, engaging social media marketing, targeted Google Ads campaigns, captivating 2D and 3D animations, or the creation of powerful online tools and other digital solutions.
`;
const description2 = `Employing advanced growth hacking techniques, our team of experts is committed to enhancing the digital footprint of our esteemed clients in today's rapidly evolving technological landscape. Based in Pakistan, we take pride in catering to a diverse clientele, both locally and internationally.`;
const AboutUs = () => {


  return (
    <>
    <MetaData metaTitle={"About"} metaDescription={"Softorithm is a cutting-edge digital agency dedicated to simplifying the digital trajectory for businesses."}/>
      {/* <AnimatePage> */}
      <div className="page_body">
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about_rgt_cont text-center">
                <h2 className="text-center">About</h2>

                <TextSlicer text={description} />
                <TextSlicer text={description2} />
              </div>
            </div>
          </div>
          <div className="videos_posts">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="yt_post_feature">
                    <span className="pst_overlay"></span>
                    <div className="pst_meta">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 18.049 18.049"
                        >
                          <g transform="translate(-2.25 -2.25)">
                            <path
                              d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                              fill="#fff"
                            />
                            <path
                              d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                              transform="translate(-6.245 -2.402)"
                              fill="#fff"
                            />
                          </g>
                        </svg>{" "}
                        a year ago
                      </span>
                      <p className="mb-0">Fitness Freak</p>
                    </div>
                    <Link to="/">
                      <span className="yt_ply_btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15.65"
                          height="18"
                          viewBox="0 0 15.65 18"
                        >
                          <path
                            d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                            transform="translate(-6.754 -5.063)"
                            fill="#fff"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="yt_mini_pst">
                        <img
                          src={yt_pst_img}
                          alt="Youtube Mini Post"
                        />
                        <span className="pst_overlay"></span>
                        <div className="pst_meta">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="8"
                              height="8"
                              viewBox="0 0 18.049 18.049"
                            >
                              <g transform="translate(-2.25 -2.25)">
                                <path
                                  d="M11.274,20.3A9.024,9.024,0,1,1,20.3,11.274,9.024,9.024,0,0,1,11.274,20.3Zm0-16.76a7.735,7.735,0,1,0,7.735,7.735A7.735,7.735,0,0,0,11.274,3.539Z"
                                  fill="#fff"
                                />
                                <path
                                  d="M20.478,17.544l-3.6-3.6V7.875h1.289v5.53l3.223,3.229Z"
                                  transform="translate(-6.245 -2.402)"
                                  fill="#fff"
                                />
                              </g>
                            </svg>{" "}
                            a year ago
                          </span>
                          <p className="mb-0">Don't be ignored</p>
                        </div>
                        <Link to="/">
                          <span className="yt_ply_btn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.65"
                              height="18"
                              viewBox="0 0 15.65 18"
                            >
                              <path
                                d="M8.561,23.063a1.73,1.73,0,0,1-.856-.228,1.923,1.923,0,0,1-.952-1.679V6.97a1.923,1.923,0,0,1,.952-1.679,1.718,1.718,0,0,1,1.75.022L21.578,12.57a1.761,1.761,0,0,1,0,2.984L9.453,22.812a1.736,1.736,0,0,1-.892.25Z"
                                transform="translate(-6.754 -5.063)"
                                fill="#fff"
                              />
                            </svg>
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* </AnimatePage> */}
    </>
  );
};
export default AboutUs;
