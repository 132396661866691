import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import AnimatePage from "../../components/AnimatePage";
import { bolg_pst_img } from "../../images";
import { Card, CardBody, CardHeader, Col, Row, Label } from "reactstrap";
import Select from "react-select";
import "../sass/blogs.scss";
// import { expolreNow } from "./DummyData";
import TextSlicer from "../../components/TextSlicer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPosts } from "../../slices/thunks";
import Loader from "../../components/Loader";
import MetaData from "../../components/MetaData";
const options = [
  { value: "", label: "Select Category" },
  { label: "Technology", value: "Technology" },
  { label: "Shopping", value: "Shopping" },
  { label: "Business", value: "Business" },
  { label: "Health", value: "Health" },
  { label: "Fashion", value: "Fashion" },
  { label: "Travel", value: "Travel" },
  { label: "Lifestyle", value: "Lifestyle" },
  { label: "Digital Marketing", value: "Digital Marketing" },
  { label: "Website", value: "Website" },
  { label: "Online Marketing", value: "Online Marketing" },
];

const description = `Explore Blogs Description`;

const Blogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { posts, isPostSuccess } = useSelector((state) => ({
    posts: state.Posts.posts,
    isPostSuccess: state.Posts.isPostSuccess,
  }));

  const [blogList, setBlogList] = useState(posts);

  useEffect(() => {
    if (posts && !posts.length) {
      dispatch(getAllPosts());
    }
  }, []);

  useEffect(() => {
    setBlogList(posts);
  }, [posts]);

  const category = (e) => {
    if (e !== "") {
      setBlogList(posts.filter((item) => item.category === e));
    } else {
      setBlogList(posts);
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleCategoryChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    category(selectedOption.value);
  };

  const searchCategory = () => {
    var searchProductList = document.getElementById("searchProductList");
    var inputVal = searchProductList.value.toLowerCase();

    function filterItems(arr, query) {
      return arr.filter(function (el) {
        return el.title.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
    }

    var filterData;

    if (inputVal.trim() === "") {
      // If the search input is empty, show all records
      setSelectedOption("");
      category(selectedOption.value);
      filterData = [...posts];
      // Create a shallow copy of the original array
    } else {
      // Otherwise, filter based on the search query
      filterData = filterItems([...blogList], inputVal); // Create a copy and filter
    }

    if (filterData.length === 0) {
      document.getElementById("noresult").style.display = "block";
      // document.getElementById("loadmore").style.display = "none";
    } else {
      document.getElementById("noresult").style.display = "none";
      // document.getElementById("loadmore").style.display = "block";
    }

    setBlogList(filterData);
  };

  const handleClick = (item) => {
    // console.log(row);
    navigate(`/blog-detail/${item.id}`, { state: item });
  };

  return (
    <>
    <MetaData metaTitle={"Blogs"} metaDescription={description}/>
      {/* <AnimatePage> */}
      <div className="blog">
        <div className="page_body">
          <div className="container mt-5">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="about_rgt_cont">
                  <h2 className="text-center">Explore Blogs</h2>

                  <TextSlicer text={description} />
                </div>
              </div>
            </div>

            <Row>
              <Col lg={12} className="mb-4">
                <Card>
                  <CardHeader className="border-0">
                    <div className="d-flex align-items-center"></div>

                    <Row className="">
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Search
                          </Label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search blog"
                            autoComplete="off"
                            id="searchProductList"
                            onKeyUp={searchCategory}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="product-title-input"
                          >
                            Select Category
                          </Label>
                          <Select
                            value={selectedOption}
                            onChange={handleCategoryChange}
                            options={options}
                            id="select-category"
                            placeholder="Select Category"
                            isSearchable={false}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            {isPostSuccess ? (
              <>
                <Row
                  className="row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1"
                  id="explorecard-list"
                >
                  {blogList &&
                    blogList?.map((item, key) => (
                      (item.status === "Pending" || item.status === "Approved") && (
                      <Col className="list-element" key={key}>
                        <div
                          onClick={() => {
                            if(item?.status !== "Pending")
                            {
                              handleClick(item);
                            }
                           
                          }}
                          style={{    cursor: `${item?.status === "Pending"? "" :"pointer"}` }}
                        >
                          <Card
                            className="explore-box card-animate"
                            style={{ filter:`${item?.status === "Pending"? "blur(5px)" :""}` }}
                          >
                            <div className="explore-place-bid-img">
                              <input
                                type="hidden"
                                className="form-control"
                                id="1"
                              />
                              <div className="d-none">undefined</div>
                              <img
                                src={item.imgOne}
                                alt=""
                                className="card-img-top explore-img"
                              />
                              <div className="bg-overlay"></div>
                            </div>

                            <CardBody>
                              <p className="mb-1">{item.title}</p>
                              <p className="text-muted mb-0">{item.category}</p>
                            </CardBody>
                          </Card>
                        </div>
                      </Col>
                    )))}
                </Row>
              </>
            ) : (
              <Loader />
            )}

            <div
              className="py-4 text-center"
              id="noresult"
              style={{ display: "none" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{ width: "72px", height: "72px" }}
              ></lord-icon>
              <h5 className="mt-4">Sorry! No Result Found</h5>
            </div>
            <div className="text-center mb-3">
              <button className="btn btn-link text-success mt-2" id="loadmore">
                {/* <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                Load More */}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </AnimatePage> */}
    </>
  );
};

export default Blogs;
