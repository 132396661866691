import React from "react";
import { Link } from "react-router-dom";
import Service from "./Service";
import { useProfile } from "../Hooks/UserHooks";
const SidebarOtherPages = ({
  handleClick,
  closeSidebar,
  writePostActive,
  converterActive,
  homeActive,
  productActive,
  serviceActive,
  guestPostActive,
  contactActive,
  aboutActive,
  blogActive,
  customerActive,
  teamActive,
  registerActive,
  profileActive,
  logoutActive,
  loginActive,
}) => {
  const { userProfile, loading, token } = useProfile();

  return (
    <>
      <ul className="side_ul  mb-2">
        {/* writePost */}
        <li>
          <Link
            to="/write-post"
            className={` ${writePostActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.writePostActiveClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">
              Write Post <i className="ri-edit-box-line small"></i>
            </span>
          </Link>
        </li>

        {/* tools */}
        <li>
          <Link
            to="/tools/imagecompress"
            className={` ${converterActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.converterClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Tools</span>
          </Link>
        </li>

        {/* home */}
        <li>
          <Link
            to="/"
            className={` ${homeActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.homeClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Home</span>
          </Link>
        </li>

        {/* product */}
        <li>
          <Link
            to="/product"
            className={` ${productActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.productClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Product</span>
          </Link>
        </li>

        {/* Services */}
        <li className={`${serviceActive ? "active" : ""}`}>
          <Link
            to=""
            className={`${serviceActive ? "active" : ""}`}
            onClick={handleClick.serviceClick}
          >
            <span className="cat_title">Sevices</span>
          </Link>

          <ul className="side_ul_in" onClick={closeSidebar}>
            <Service />
          </ul>
        </li>

        {/* guest post */}
        <li>
          <Link
            to="/guest-post"
            className={` ${guestPostActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.guestPostClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Guest Post</span>
          </Link>
        </li>

        {/* contact */}
        <li>
          <Link
            to="/contact-us"
            className={` ${contactActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.contactClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Contact</span>
          </Link>
        </li>

        {/* about */}
        <li>
          <Link
            to="/about"
            className={` ${aboutActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.aboutClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">About</span>
          </Link>
        </li>

        {/* blog */}
        <li>
          <Link
            to="/blogs"
            className={` ${blogActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.blogClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Blog</span>
          </Link>
        </li>

        {/* customers */}
        <li>
          <Link
            to="/customers"
            className={` ${customerActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.customerClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Customers</span>
          </Link>
        </li>

        {/* team */}
        <li>
          <Link
            to="/team"
            className={` ${teamActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.teamClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Team</span>
          </Link>
        </li>

        {/* login*/}
        <li>
          <Link
            to="/login"
            className={` ${loginActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              // handleClick.loginClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Login</span>
          </Link>
        </li>

        {/* logout */}
        {userProfile && (
          <li>
            <Link
              to="/logout"
              className={` ${logoutActive ? "active" : ""} no_after_effect`}
              onClick={() => {
                //  handleClick.logoutClick();
                closeSidebar();
              }}
            >
              <span className="cat_title">Logout</span>
            </Link>
          </li>
        )}

        {/* register */}
        <li>
          <Link
            to="/register"
            className={` ${registerActive ? "active" : ""} no_after_effect`}
            onClick={() => {
              handleClick.registerClick();
              closeSidebar();
            }}
          >
            <span className="cat_title">Register</span>
          </Link>
        </li>

        {/* profile */}
        {userProfile && (
          <li>
            <Link
              to="/user-profile"
              className={` ${profileActive ? "active" : ""} no_after_effect`}
              onClick={() => {
                handleClick.profileClick();
                closeSidebar();
              }}
            >
              <span className="cat_title">Profile</span>
            </Link>
          </li>
        )}
      </ul>
    </>
  );
};

export default SidebarOtherPages;
