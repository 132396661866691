import React from "react";
import TextSlicer from "../../components/TextSlicer";
import { Row, Col, Card, CardBody } from "reactstrap";
import { img6 } from "../../images";
import { Link } from "react-router-dom";
import MetaData from "../../components/MetaData";


const description = `Our Products Description`;

const Product = () => {
  return (
    <>
<MetaData metaTitle={"Product"} metaDescription={description}/>
      <div className="page_body">
        <div className="container mt-5">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="about_rgt_cont">
                <h2 className="text-center">Products</h2>

                <TextSlicer text={description} />
              </div>
            </div>
          </div>

          <Row>
            <Col xxl={6} lg={6}>
              <Card>
                <img src={img6} className="card-img-top" alt="..." />
                <CardBody>
                  <h5 className="card-title">Card with stretched link</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <Link to="#" className="btn btn-success stretched-link">
                    Read more
                  </Link>
                </CardBody>
              </Card>
            </Col>

            <Col xxl={6} lg={6}>
              <Card>
                <img src={img6} className="card-img-top" alt="..." />
                <CardBody>
                  <h5 className="card-title">Card with stretched link</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  <Link to="#" className="btn btn-success stretched-link">
                    Read more
                  </Link>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Product;
