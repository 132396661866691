import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { poster_img, bolg_feature_img, avatar3 } from "../../images";
import "../sass/blog_detail.scss";

import { useLocation, Navigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import RecentPost from "./RecentPost";
import Select from "react-select";
import CommentSection from "./CommentSection";
import { useDispatch, useSelector } from "react-redux";
import {
  PostById,
  getAllCommentsByPostId,
  getAllRepliesByPostId,
} from "../../slices/thunks";
import withRouter from "../../components/withRouter";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
const allMonths = [
  { label: "January", value: "January" },
  { label: "Febuary", value: "Febuary" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

const Blog_detail = (props) => {
  const location = useLocation();
  const data = location.state;
  const { slug } = useParams();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [item, setItem] = useState(data);

  const {
    postById,
    isPostByIdSuccess,
    comments,
    getAllCommentsLoading,
    replies,
    getAllRepliesLoading,
  } = useSelector((state) => ({
    postById: state.Posts.postById,
    isPostByIdSuccess: state.Posts.isPostByIdSuccess,
    comments: state.Comment.comments,
    getAllCommentsLoading: state.Comment.getAllCommentsLoading,
    replies: state.Comment.replies,
    getAllRepliesLoading: state.Comment.getAllRepliesLoading,
  }));

  useEffect(() => {
    if (!data && slug) {
      dispatch(PostById(slug, props.router.navigate));
      dispatch(getAllCommentsByPostId(slug));
    }
  }, [data, dispatch, props.router.navigate, slug]);

  useEffect(() => {
    if (postById?.posts) {
      setItem(postById?.posts);
    }
  }, [postById?.posts]);

  useEffect(() => {
    dispatch(getAllCommentsByPostId(slug));
    dispatch(getAllRepliesByPostId(slug));
  }, []);

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const defaultMonth = allMonths.find(
    (option) => option.label === currentMonth
  );
  const [month, setMonth] = useState(defaultMonth);

  if (!slug) {
    return <Navigate to={{ pathname: "/blogs" }} />;
  }
  if (!data && slug) {
    if (postById?.status === false) {
      return <Navigate to={{ pathname: "/blogs" }} />;
    }
  }

  // if (!slug) {
  //   return <Navigate to={{ pathname: "/blogs" }} />;
  // }
  return (
    <>
      <div className="page_body blogdetails">
        <div className="container mt-4">
          <Container fluid>
            <Row>
              <Col xxl={9}>
                <Card>
                  <CardBody>
                    {item ? (
                      <>
                        {" "}
                        <div className="blogs_details">
                          <h2>{item?.title}</h2>
                          <p className="blg_dls_meta ">
                           Posted By
                            {/* <img
                              src={item?.userImg }
                              alt="user"
                            />{" "} */}
                            <label className="ms-2">
                            {item?.createdByName}
                              </label>
                            
                            <span className="ms-2">
                              {item?.createdDate &&
                                new Date(item?.createdDate)
                                  .toISOString()
                                  .split("T")[0]}
                            </span>
                          </p>
                          <ul className="blg_d_media">
                            <li>
                              <Link to="/facebook" className="fb_meta">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="8.749"
                                    height="18"
                                    viewBox="0 0 8.749 18"
                                  >
                                    <path
                                      d="M2.234,18V9.554H0V6.513H2.234v-2.6C2.234,1.875,3.553,0,6.593,0a18.457,18.457,0,0,1,2.14.118l-.071,2.84s-.928-.009-1.941-.009c-1.1,0-1.272.505-1.272,1.344v2.22h3.3L8.6,9.554H5.449V18H2.234"
                                      fill="#fff"
                                    />
                                  </svg>{" "}
                                </span>{" "}
                                Share
                              </Link>
                            </li>

                            <li>
                              <Link to="/twitter" className="tw_meta">
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22.163"
                                    height="18"
                                    viewBox="0 0 22.163 18"
                                  >
                                    <path
                                      d="M22.432,2.138a9.506,9.506,0,0,1-2.278,2.348q.014.2.014.591a12.905,12.905,0,0,1-.534,3.649A13.191,13.191,0,0,1,18.01,12.22a13.734,13.734,0,0,1-2.595,2.961,11.545,11.545,0,0,1-3.628,2.053A13.3,13.3,0,0,1,7.244,18,12.614,12.614,0,0,1,.269,15.961a9.716,9.716,0,0,0,1.1.056A8.891,8.891,0,0,0,7,14.077a4.549,4.549,0,0,1-4.246-3.151,5.777,5.777,0,0,0,.858.07,4.707,4.707,0,0,0,1.195-.154A4.467,4.467,0,0,1,2.2,9.274,4.386,4.386,0,0,1,1.17,6.384V6.328A4.5,4.5,0,0,0,3.223,6.9,4.535,4.535,0,0,1,1.747,5.287,4.432,4.432,0,0,1,1.2,3.122,4.449,4.449,0,0,1,1.817.83,12.865,12.865,0,0,0,5.958,4.184a12.662,12.662,0,0,0,5.224,1.4,5.045,5.045,0,0,1-.112-1.041A4.377,4.377,0,0,1,12.4,1.329a4.553,4.553,0,0,1,6.532.106,8.89,8.89,0,0,0,2.882-1.1,4.413,4.413,0,0,1-2,2.5,9.054,9.054,0,0,0,2.616-.7Z"
                                      transform="translate(-0.269)"
                                      fill="#fff"
                                    />
                                  </svg>
                                </span>{" "}
                                Tweet
                              </Link>
                            </li>

                            <li>
                              <Link to="/pintrest" className="pin_meta">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.847"
                                  height="18"
                                  viewBox="0 0 13.847 18"
                                >
                                  <path
                                    d="M0,6.458a5.971,5.971,0,0,1,.406-2.2,5.644,5.644,0,0,1,1.12-1.8A7.647,7.647,0,0,1,5.17.281,8.549,8.549,0,0,1,7.356,0a7.143,7.143,0,0,1,3.181.72,6,6,0,0,1,2.39,2.093,5.464,5.464,0,0,1,.92,3.1,10.028,10.028,0,0,1-.206,2.034,8.216,8.216,0,0,1-.649,1.915,6.67,6.67,0,0,1-1.082,1.617A4.693,4.693,0,0,1,10.342,12.6a4.948,4.948,0,0,1-2.045.417,3.363,3.363,0,0,1-1.461-.346A2.167,2.167,0,0,1,5.8,11.715q-.108.422-.3,1.217T5.241,13.96q-.059.233-.222.768a5.182,5.182,0,0,1-.282.768l-.346.676a7.761,7.761,0,0,1-.5.839q-.27.4-.671.936L3.072,18l-.1-.108q-.163-1.7-.163-2.034a12.229,12.229,0,0,1,.233-2.234q.233-1.239.72-3.109t.563-2.2a4.153,4.153,0,0,1-.346-1.828A2.856,2.856,0,0,1,4.544,4.8a1.692,1.692,0,0,1,1.428-.79A1.268,1.268,0,0,1,7,4.452,1.666,1.666,0,0,1,7.367,5.56a6.846,6.846,0,0,1-.476,2.066A6.825,6.825,0,0,0,6.415,9.65,1.472,1.472,0,0,0,6.9,10.78a1.677,1.677,0,0,0,1.179.449,2.314,2.314,0,0,0,1.1-.271,2.487,2.487,0,0,0,.849-.736A6.246,6.246,0,0,0,10.64,9.2,5.875,5.875,0,0,0,11.051,8a12.057,12.057,0,0,0,.217-1.2,8.62,8.62,0,0,0,.071-1.077,3.683,3.683,0,0,0-1.185-2.916A4.5,4.5,0,0,0,7.064,1.763a4.994,4.994,0,0,0-3.613,1.4A4.731,4.731,0,0,0,2,6.718,3.419,3.419,0,0,0,2.428,8.34q.157.26.292.492a.835.835,0,0,1,.136.331,2.629,2.629,0,0,1-.163.79q-.163.487-.4.487-.022,0-.184-.033A2.216,2.216,0,0,1,1.131,9.8a3.152,3.152,0,0,1-.66-1.022A6.661,6.661,0,0,1,.119,7.61,5.82,5.82,0,0,1,0,6.458Z"
                                    fill="#fff"
                                  />
                                </svg>
                              </Link>
                            </li>

                            <li>
                              <Link to="/mail" className="envel_meta">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22.91"
                                  height="18"
                                  viewBox="0 0 22.91 18"
                                >
                                  <path
                                    d="M21.274,15.955V6.137a7.525,7.525,0,0,1-.882.844Q16.965,9.614,14.945,11.3q-.652.55-1.061.857a5.881,5.881,0,0,1-1.106.62,3.212,3.212,0,0,1-1.311.313h-.025a3.208,3.208,0,0,1-1.31-.313,5.908,5.908,0,0,1-1.106-.62q-.409-.307-1.061-.857Q5.945,9.614,2.519,6.98a7.525,7.525,0,0,1-.882-.844v9.819a.415.415,0,0,0,.409.409H20.864a.415.415,0,0,0,.409-.409Zm0-13.437V2.205l-.006-.166-.038-.16-.07-.115-.115-.1-.179-.032H2.045a.415.415,0,0,0-.409.409A4.474,4.474,0,0,0,3.515,5.676Q5.983,7.619,8.642,9.728l.447.377q.37.314.588.479t.569.4a3.342,3.342,0,0,0,.646.352,1.534,1.534,0,0,0,.55.115h.025a1.513,1.513,0,0,0,.55-.115,3.432,3.432,0,0,0,.646-.351q.352-.236.568-.4t.588-.479l.447-.377q2.659-2.11,5.127-4.052A6.01,6.01,0,0,0,20.679,4.2a3.2,3.2,0,0,0,.6-1.681Zm1.636-.473V15.955A2.051,2.051,0,0,1,20.864,18H2.045A1.97,1.97,0,0,1,.6,17.4,1.97,1.97,0,0,1,0,15.955V2.045A1.97,1.97,0,0,1,.6.6,1.97,1.97,0,0,1,2.045,0H20.864a1.97,1.97,0,0,1,1.445.6,1.97,1.97,0,0,1,.6,1.445Z"
                                    fill="#fff"
                                  />
                                </svg>
                              </Link>
                            </li>

                            <li className="me-0">
                              <Link to="/" className="ellip_meta ps-0">
                                <i className="fa fa-ellipsis-v"></i>
                              </Link>
                            </li>
                          </ul>

                          <div className="bd_img">
                            <img src={item?.imgOne} alt="bolg feature" />
                          </div>
                          <p
                            className="bd_p mt-4"
                            dangerouslySetInnerHTML={{
                              __html: item?.descriptionOne,
                            }}
                          ></p>
                          {item?.imgTwo && item?.descriptionTwo ? (
                            <>
                              <div className="bd_img">
                                <img
                                  src={item?.imgTwo || ""}
                                  alt="bolg post two"
                                />
                              </div>
                              <p
                                className="bd_p mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: item?.descriptionTwo,
                                }}
                              ></p>
                            </>
                          ) : (
                            ""
                          )}
                          {item?.imgThree && item?.descriptionThree ? (
                            <>
                              <div className="bd_img">
                                <img
                                  src={item?.imgThree || ""}
                                  alt="bolg post two"
                                />
                              </div>
                              <p
                                className="bd_p mt-4"
                                dangerouslySetInnerHTML={{
                                  __html: item?.descriptionThree,
                                }}
                              ></p>
                            </>
                          ) : (
                            ""
                          )}

                          <div className="mb_30"></div>
                        </div>
                      </>
                    ) : (
                      <Loader />
                    )}
                  </CardBody>
                </Card>

                <div className="">
                  <CommentSection
                    item={item}
                    comments={comments}
                    getAllCommentsLoading={getAllCommentsLoading}
                    replies={replies}
                    getAllRepliesLoading={getAllRepliesLoading}
                  />
                </div>

                {/* comment section */}
              </Col>

              <Col xxl={3}>
                <RecentPost />
                {/* <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Archives</h5>
                  </CardHeader>

                  <CardBody>
                    <div>
                      <Select
                        value={month}
                        onChange={(value) => {
                          setMonth(value);
                        }}
                        options={allMonths}
                        id="choices-single-default"
                        className="js-example-basic-single mb-0"
                        name="month"
                      />
                    </div>
                  </CardBody>
                </Card> */}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default withRouter(Blog_detail);
