import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SidebarState from "./context/sidebar/SidebarState";
import LoadingSpinner from "./components/LoadingSpinner";


// Import CSS
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/responsive.css";

// Import Layouts and Components
import DefaultLayout from "./layout/DefaultLayout";

import Logout from "./page/logout/Logout"

const App = () => {
  return (
    <>
      <SidebarState>
        <BrowserRouter>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                exact
                path="notfound"
                name="Not Found"
                element={<NotFound />}
              />
              <Route exact path="login" name="Login" element={<Login />} />
              <Route exact path="logout" name="Logout" element={<Logout />} />
              <Route
                exact
                path="register"
                name="Register"
                element={<Register />}
              />
              <Route
                exact
                path="tools/*"
                name="Tools"
                element={<ToolsLayout />}
              />
            
              <Route path="*" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </SidebarState>
    </>
  );
};

// Lazy-loaded components
const NotFound = React.lazy(() => import("./views/notfound/NotFound"));
const Login = React.lazy(() => import("./page/login/Login"));
const Register = React.lazy(() => import("./page/register/Register"));
const ToolsLayout = React.lazy(() => import("./layout/ToolsLayout"));

export default React.memo(App);
