import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
// routes config
import routes from "../routes";
const ToolsContent = () => {
  const loading = <LoadingSpinner />;
  const location = useLocation();

  const [showFooter, setShowFooter] = useState(true);
  useEffect(() => {
    // Check if the current route is home ("/")
    if (location.pathname.includes("tools/contractpolicy")) {
      setShowFooter(false);
    } else {
      setShowFooter(true);
    }
  }, [location.pathname]);

  return (
    <>
      <div className="Tools_Content content_wrapper">
        <Suspense fallback={loading}>
          <Routes key={location.pathname} location={location}>
            {routes.map((route) => {
              return (
                route.element && (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element category={route.name} />}
                  />
                )
              );
            })}
            <Route path="*" element={<Navigate to="notfound" replace />} />
            <Route path="/" element={<Navigate to="comparetext" replace />} />
          </Routes>
          {showFooter && (
            <footer className="p-4 mt-4 bg-dark position-relative services_footer_tool">
              <div className="bg-overlay bg-overlay-pattern opacity-50"></div>
              <Container>
                <Row
                  className="align-items-center"
                  style={{ margin: "0px 40px" }}
                >
                  <Col lg={8} className="text-center text-lg-start">
                    <div className="mb-3 mb-lg-0">
                      <h5 className="text-white mb-0 ">
                        Power Up Your Business: Get Started Today
                      </h5>
                    </div>
                  </Col>
                  <Col lg={4} className="text-center text-lg-end">
              <div className="connect_container">
                <Link
                  to="/contact-us"
                  target="_blank"
                  className="btn convert_btn"
                >
                  Connect With Us
                </Link>
                <ul className="social_icons mt-1">
                  <li>
                    <Link to="instagram.com/softorithm" className="icon_link">
                      <i className="ri-instagram-line instagram_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="facebook.com/softorithm" className="icon_link">
                      <i className="ri-facebook-box-fill facebook_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/@softorithm"
                      className="icon_link"
                    >
                      <i className="ri-youtube-line youtube_icon"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.tiktok.com/@softorithm"
                      className="icon_link"
                    >
                      <i className="ri-tiktok-fill tiktok_icon"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>         
            

                </Row>
              </Container>
            </footer>
          )}
        </Suspense>
      </div>
    </>
  );
};

export default ToolsContent;
