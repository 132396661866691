import React from "react";

import { NavLink } from "react-router-dom";
const ConverterList = () => {

  return (
    <>
      <li>
        <NavLink to="/tools/imagecompress">Image Compressor</NavLink>
      </li>
      <li>
        <NavLink to="/tools/jpgtopng">JPG to PNG </NavLink>
      </li>
      <li>
        <NavLink to="/tools/pngtojpg">PNG to JPG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pngtopdf">PNG to PDF</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdftopng">PDF to PNG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdftojpg">PDF to JPG</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pdftoword">PDF to WORD</NavLink>
      </li>
      {/* <li>
        <Link  to="/tools/wordtopdf">WORD to PDF</Link>
      </li> */}
      <li>
        <NavLink to="/tools/mp4tomp3">MP4 to MP3</NavLink>
      </li>
      <li>
        <NavLink to="/tools/mp3tomp4">MP3 to MP4</NavLink>
      </li>
      <li>
        <NavLink to="/tools/pngtogif">PNG to GIF</NavLink>
      </li>
      <li>
        <NavLink to="/tools/jpgtogif">JPG to GIF</NavLink>
      </li>

      <li>
        <NavLink to="/tools/bgremover">Background Remover</NavLink>
      </li>

      <li>
        <NavLink to="/tools/image-resizer">Image Resizer</NavLink>
      </li>
    </>
  );
};

export default ConverterList;
