//API ENDPOINTS
//LOGIN
export const USER_LOGIN = "Account/Login";

//REGISTER
export const USER_REGISTER = "Account/Register_User"
//Users
export const ALL_USERS = "Account/GetAllUser";
export const CREATE_USER = "Account/Register_User";
export const UPDATE_USER = "Account/Update_User";
export const DELETE_USER = "Account/DeleteUser";

//Posts
export const ALL_POSTS = "Account/GetAllPosts";
export const CREATE_POST = "Account/PostBlog";
export const DELETE_POST = "Account/DeletePostBlog";
export const UPDATE_POST = "Account/PostBlog";
export const ALL_RECENT_APPROVED_POSTS = "Account/GetRecentApprovedPost";
export const GET_POST_BY_ID = "Account/GetPostBlogById";
export const GET_USER_POST_BY_ID = "Account/GetAllPostBlogByUserId"


// Messages / Comments / Reply

export const CREATE_COMMENT = "Account/SaveComment"
export const GET_COMMENT_BYPOST_ID = "Account/GetAllCommentByPostId"
export const CREATE_REPLY = "Account/SaveCommentReply"
export const GET_REPLIES_BYPOST_ID = "Account/GetAllReplyByPostId"