import React from "react";
import NotFound from "../src/views/notfound/NotFound";


//// TOOLS - TEXT_EDITOR
const PhoneNoExtractor = React.lazy(() =>import("./tools/text-editor/PhoneNoExtractor"));
const CompareText = React.lazy(() => import("./tools/text-editor/CompareText"));
const RemoveExtraSpace = React.lazy(() =>import("./tools/text-editor/RemoveExtraSpace"));
const TextCounter = React.lazy(() => import("./tools/text-editor/TextCounter"));
const CharacterReplace = React.lazy(() =>import("./tools/text-editor/CharacterReplace"));
const NumberExtractor = React.lazy(() =>import("./tools/text-editor/NumberExtractor"));
const RemoveCharacter = React.lazy(() =>import("./tools/text-editor/RemoveCharacter"));
const TextTransform = React.lazy(() =>import("./tools/text-editor/TextTransform"));
const RemoveLineBreak = React.lazy(() => import ('./tools/text-editor/RemoveLineBreak'));
const RemoveDuplicateLines = React.lazy(()=> import('./tools/text-editor/RemoveDuplicateLines'));
const AddLineBreak = React.lazy(()=> import ('./tools/text-editor/AddLineBreak'));
const ParagraphToSingleLine = React.lazy(()=> import('./tools/text-editor/ParagraphToSingleLine'));
const LineBreaksToparagraphs = React.lazy(() => import('./tools/text-editor/LineBreaksToparagraphs'));
//// TOOLS - CONVERTER LAZYLOAD

const JPGtoPNG = React.lazy(() =>
  import("./tools/converter/imageConverter/JPGtoPNG")
);
const PDFtoJPG = React.lazy(() => import("./tools/converter/PDFtoJPG"));
const PDFtoPNG = React.lazy(() => import("./tools/converter/PDFtoPNG"));
const PDFtoWORD = React.lazy(() => import("./tools/converter/PDFtoWORD"));
const PNGtoJPG = React.lazy(() =>
  import("./tools/converter/imageConverter/PNGtoJPG")
);
const PNGtoPDF = React.lazy(() =>
  import("./tools/converter/imageConverter/PNGtoPDF")
);
const ImageCompress = React.lazy(() =>
  import("./tools/converter/imagecompressor/ImageCompress")
);
const PNGtoGif = React.lazy(() =>
  import("./tools/converter/imageConverter/PNGtoGif")
);
const JPGtoGIF = React.lazy(() =>
  import("./tools/converter/imageConverter/JPGtoGIF")
);

const MP4toMP3 = React.lazy(() =>
  import("./tools/converter/mp4tomp3/MP4toMP3")
);
const Mp3toMp4 = React.lazy(() =>
  import("./tools/converter/mp3tomp4/Mp3toMp4")
);
const WORDtoPDF = React.lazy(() => import("./tools/converter/WORDtoPDF"));
const BgRemover = React.lazy(() => import("./tools/converter/BgRemover"));
const ImageResizer = React.lazy(() => import("./tools/converter/ImageResizer"));
const HeightConverter = React.lazy(() =>
  import("./tools/converter/HeightConverter")
);

//// TOOLS GENERATOR LAZYLOAD

const InvoiceGenerator = React.lazy(()=> import('./tools/generator/InvoiceGenerator'));

const BusinessNameGenerator = React.lazy(() =>
  import("./tools/generator/BusinessNameGenerator")
);
const GradientGenerator = React.lazy(() =>
  import("./tools/generator/GradientGenerator")
);
const HtmlColorGenerator = React.lazy(() =>
  import("./tools/generator/HtmlColorGenerator")
);
const NickNamesGenerator = React.lazy(() =>
  import("./tools/generator/NickNamesGenerator")
);
const NumberGenerator = React.lazy(() =>
  import("./tools/generator/NumberGenerator")
);
const PasswordGenerator = React.lazy(() =>
  import("./tools/generator/PasswordGenerator")
);
const QrCodeGenerator = React.lazy(() =>
  import("./tools/generator/QrCodeGenerator")
);
const TextGenerator = React.lazy(() =>
  import("./tools/generator/TextGenerator")
);
const WebFontGenerator = React.lazy(() =>
  import("./tools/generator/WebFontGenerator")
);
const ColorMixer = React.lazy(() => import("./tools/generator/ColorMixer"));
const PrivacyPolicyGenerator = React.lazy(() =>
  import("./tools/generator/PrivacyPolicyGenerator")
);
const ContractPolicyGenerator = React.lazy(() =>
  import("./tools/generator/ContractPolicyGenerator")
);

//// TOOLS DOWNLOADER LAZYLOAD
const VideoDownloader = React.lazy(() =>
  import("./tools/downloader/VideoDownloader")
);

//// TOOLS IMAGE EDITOR
const ImageEditor = React.lazy(() =>
  import("./tools/image-editor/ImageEditor")
);
const GraphicEditor = React.lazy(() =>
  import("./tools/image-editor/design_editor/GraphicEditor")
);

//// CALCULATOR
const AgeCalculator = React.lazy(() =>
  import("./tools/calculator/AgeCalculator")
);
const PercentageCalculator = React.lazy(() =>
  import("./tools/calculator/PercentageCalculator")
);
const AverageCalculator = React.lazy(() =>
  import("./tools/calculator/AverageCalculator")
);
const SalesTaxCalculator = React.lazy(() =>
  import("./tools/calculator/SalesTaxcalculator")
);
const MarginCalculator = React.lazy(() =>
  import("./tools/calculator/MarginCalculator")
);
const GstCalculator = React.lazy(() =>
  import("./tools/calculator/GstCalculator")
);
const LoanCalculator = React.lazy(() =>
  import("./tools/calculator/LoanCalculator")
);
const CpmCalculator = React.lazy(() =>
  import("./tools/calculator/CpmCalculator")
);
const DiscountCalculator = React.lazy(() =>
  import("./tools/calculator/DiscountCalculator")
);
const PaypalFeeCalculator = React.lazy(() =>
  import("./tools/calculator/PaypalFeeCalculator")
);

///// BINARY CONVERTER
const TextToBinary = React.lazy(() =>import("./tools/binaryconverter/TextToBinary"));
const BinaryToText = React.lazy(()=> import("./tools/binaryconverter/BinaryToText"));
const HexToBinary = React.lazy(()=> import("./tools/binaryconverter/HexToBinary"));
const BinaryToHex = React.lazy(()=> import ('./tools/binaryconverter/BinaryToHex'));
const AsciiToBinary = React.lazy(()=>import("./tools/binaryconverter/AsciiToBinary"));
const BinaryToAscii = React.lazy(()=> import ("./tools/binaryconverter/BinaryToAscii"));
const DecimalToBinary = React.lazy(()=> import ("./tools/binaryconverter/DecimalToBinary"));
const BinaryToDecimal = React.lazy(()=> import ("./tools/binaryconverter/BinaryToDecimal"));
const TextToAscii = React.lazy(()=> import ("./tools/binaryconverter/TextToAscii"));
const AsciiToText = React.lazy(()=> import ("./tools/binaryconverter/AsciiToText"));
//  const InstagramTag = React.lazy(()=> import ("./tools/generator/InstagramTag"));
const HexTodecimal = React.lazy(()=> import ("./tools/binaryconverter/HexTodecimal"));
const DecimalToHex = React.lazy(()=> import ("./tools/binaryconverter/DecimalToHex"));
const OctalToBinary = React.lazy(() => import ("./tools/binaryconverter/OctalTobinary"));
const BinaryToOctal = React.lazy(()=> import ("./tools/binaryconverter/BinaryToOctal"));
const OctalToDecimal = React.lazy(()=> import("./tools/binaryconverter/OctalToDecimal"));
const DecimalToOctal = React.lazy(()=> import("./tools/binaryconverter/DecimalToOctal"));
const HexToOctal = React.lazy(()=> import('./tools/binaryconverter/HexToOctal'));
const OctalToHex = React.lazy(()=> import ('./tools/binaryconverter/OctalToHex'));
const TextToOctal = React.lazy(()=> import('./tools/binaryconverter/TextToOctal'));
const OctalToText = React.lazy(()=> import ('./tools/binaryconverter/OctalToText'));

const routes = [
  { path: "notfound", name: "Not Found", element: NotFound },

  //// TOOLS - BINARY_CONVERTER
  { path: "text-to-binary", name: "Text To Binary", element: TextToBinary },
  { path: "binary-to-text", name:" Binary To Text", element:BinaryToText},
  { path: "hex-to-binary", name: "Hex To Binary", element:HexToBinary},
  { path: "binary-to-hex", name: "Binary To Hex", element:BinaryToHex},
  { path: "ascii-to-binary", name: "ASCII To Binary", element:AsciiToBinary},
  { path: "binary-to-ascii", name: "Binary To ASCII", element:BinaryToAscii},
  { path: "decimal-to-binary", name: "Decimal To Binary", element:DecimalToBinary},
  { path: "binary-to-decimal", name: "Binary To Decimal", element:BinaryToDecimal},
  { path: "text-to-ascii", name:"Text To Ascii", element:TextToAscii},
  { path: "ascii-to-text", name:"Ascii To Text", element:AsciiToText},
  { path: "hex-to-decimal", name:"Hex To Decimal", element:HexTodecimal},
  { path: "decimal-to-hex", name:"Decimal To Hex", element:DecimalToHex},
  { path: "octal-to-binary", name:"Octal To Binary", element:OctalToBinary},
  { path: "binary-to-octal", name:"Binary To Octal", element:BinaryToOctal},
  { path:"octal-to-decimal", name:"Octal To Decimal", element:OctalToDecimal},
  { path:"decimal-to-octal", name:"Decimal To Octal", element:DecimalToOctal},
  { path:"hex-to-octal", name:"Hex To Octal", element:HexToOctal},
  { path:"octal-to-hex", name:"Octal To Hex", element:OctalToHex},
  { path:"text-to-octal", name:"Text To Octal", element:TextToOctal},
  { path:"octal-to-text", name: "Octal To text", element:OctalToText},
  
  // { path: "instagram", naem:"Ascii To Text", element:InstagramTag},


  //// TOOLS - TEXT_EDITOR
  { path :'linebreaks-to-paragraph', name :'Convert Line Breaks to Paragraphs', element:LineBreaksToparagraphs},
  { path :'paragraph-to-lines', name :'Paragraph To Line', element:ParagraphToSingleLine},
  { path :'add-line-break', name :"Add Line Break", element:AddLineBreak},
  { path:'remove-line-break', name:"Remove Line Break", element:RemoveLineBreak},
  { path:'remove-duplicate-lines', name:"Remove Duplicate Lines", element:RemoveDuplicateLines},
  { path: "phonenoextractor",name: "Phone No Extractor",element: PhoneNoExtractor,},
  { path: "comparetext", name: "Compare Text", element: CompareText },
  { path: "textcounter", name: "Text Counter", element: TextCounter },
  { path: "removeextraspace",name: "Remove Extraspace",element: RemoveExtraSpace,},
  { path: "characterreplace",name: "Character Replace",element: CharacterReplace,},

  { path: "numberextractor",name: "Number Extractor",element: NumberExtractor,},
  { path: "removecharacter",name: "Remove Character",element: RemoveCharacter,},
  { path: "texttransform", name: "Text Transform", element: TextTransform },

  //// TOOLS - CONVERTER
  { path: "jpgtopng", name: "JPG to PNG", element: JPGtoPNG },
  { path: "mp4tomp3", name: "MP4 to MP3", element: MP4toMP3 },
  { path: "mp3tomp4", name: "Mp3 to Mp4", element: Mp3toMp4 },
  { path: "wordtopdf", name: "WORD to PDF", element: WORDtoPDF },
  { path: "pdftojpg", name: "PDF to JPG", element: PDFtoJPG },
  { path: "pdftopng", name: "PDF to PNG", element: PDFtoPNG },
  { path: "pdftoword", name: "PDF to WORD", element: PDFtoWORD },
  { path: "pngtojpg", name: "PNG to JPG", element: PNGtoJPG },
  { path: "pngtopdf", name: "PNG to PDF", element: PNGtoPDF },
  { path: "imagecompress", name: "Image Compress", element: ImageCompress },
  { path: "pngtogif", name: "PNG to GIF", element: PNGtoGif },
  { path: "jpgtogif", name: "JPG to GIF", element: JPGtoGIF },
  { path: "image-resizer", name: "Image Resizer", element: ImageResizer },
  { path: "height-converter",name: "Height Converter",element: HeightConverter,},

  //// TOOLS - GENERATOR

  { path:"invoice-generator" , name: "Invoice Generator", element: InvoiceGenerator },

  {
    path: "contractpolicy",
    name: "Contract Policy Generator",
    element: ContractPolicyGenerator,
  },
  { path: "colormixer", name: "Color Mixer", element: ColorMixer },
  {
    path: "privacygenerator",
    name: "Privacy Policy generator",
    element: PrivacyPolicyGenerator,
  },
  {
    path: "businessnamegenerator",
    name: "Business Name Generator",
    element: BusinessNameGenerator,
  },
  {
    path: "gradientgenerator",
    name: "Gradient Generator",
    element: GradientGenerator,
  },
  {
    path: "htmlcolorgenerator",
    name: "HtmlColor Generator",
    element: HtmlColorGenerator,
  },
  {
    path: "nicknamesgenerator",
    name: "NickNames Generator",
    element: NickNamesGenerator,
  },
  {
    path: "numbergenerator",
    name: "Number Generator",
    element: NumberGenerator,
  },
  {
    path: "passwordgenerator",
    name: "Password Generator",
    element: PasswordGenerator,
  },
  {
    path: "qrcodegenerator",
    name: "QrCode Generator",
    element: QrCodeGenerator,
  },
  { path: "textgenerator", name: "Text Generator", element: TextGenerator },
  {
    path: "webfontgenerator",
    name: "WebFont Generator",
    element: WebFontGenerator,
  },
  { path: "bgremover", name: "Background Remover", element: BgRemover },

  //// TOOLS -DOWNLOADER
  {
    path: "videodownloader",
    name: "Video Downloader",
    element: VideoDownloader,
  },

  //// IMAGE EDITOR
  { path: "imageeditor", name: "Image Editor", element: ImageEditor },
  { path: "graphiceditor", name: "Graphic Editor", element: GraphicEditor },

  //// CAlCULATOR
  { path: "agecalculator", name: "Age Calculator", element: AgeCalculator },
  {
    path: "percentage-calculator",
    name: "Percwentage Calculator",
    element: PercentageCalculator,
  },
  {
    path: "average-calculator",
    name: "Average Calculator",
    element: AverageCalculator,
  },
  {
    path: "salestax-calculator",
    name: "Sales Tax Calculator",
    element: SalesTaxCalculator,
  },
  {
    path: "margin-calculator",
    name: "Margin Calculator",
    element: MarginCalculator,
  },
  { path: "gst-calculator", name: "GST Calculator", element: GstCalculator },
  { path: "loan-calculator", name: "Loan Calculator", element: LoanCalculator },
  { path: "cpm-calculator", name: "CPM Calculator", element: CpmCalculator },
  {
    path: "discount-calculator",
    name: "Discount Calculator",
    element: DiscountCalculator,
  },
  {
    path: "paypalfee-calculator",
    name: "PayPalFee Calculator",
    element: PaypalFeeCalculator,
  },
];

export default routes;
