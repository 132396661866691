import React from "react";
import TextSlicer from "../../components/TextSlicer";
import { Card, Row, Col, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { teamImg1, teamImg2, teamImg3, teamImg4, teamImg5, teamImg6 } from "../../images";
import MetaData from "../../components/MetaData";
const description = `Welcome to Softorithm, where innovation meets expertise. Our dedicated team is passionate about delivering exceptional web development, graphic design, UI/UX design, and digital marketing services. With a blend of creativity and technical prowess, we bring your digital vision to life. Get to know the minds behind our success – a team committed to shaping digital excellence and empowering your brand's journey.`;
const OurTeam = () => {
  return (
    <>
       <MetaData metaTitle={"Team"} metaDescription={"Welcome to Softorithm, where innovation meets expertise. Our dedicated team is passionate about delivering exceptional web development, graphic design, UI/UX design, and digital marketing services."}/>
      <div className="page_body">
        <div className="container mt-5">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12">
              <div className="about_rgt_cont">
                <h2 className="text-center mb-3">Team</h2>

                <strong>Meet the Innovators at Softorithm </strong>
                <TextSlicer text={description} />
              </div>
            </div>

            <Row className="justify-content-center team_bxs">
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg1}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Ali Idrees</h5>
                    <p className="text-muted mb-0">Director</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.facebook.com/aliidreesbutt/" className="lh-1 align-middle link-primary">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/aliidrees25/" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="https://www.youtube.com/@softorithm/" className="lh-1 align-middle link-danger">
                          <i className="ri-youtube-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="https://www.tiktok.com/@softorithm"
                          className="lh-1 align-middle link-secondary"
                        >
                          <i className="ri-tiktok-fill"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg2}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Umair Shuja</h5>
                    <p className="text-muted mb-0">Lead. Engineer</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.facebook.com/umair.shuja" className="lh-1 align-middle link-primary">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/umair-shuja-0b44b548/" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="https://www.instagram.com/umair.shuja143/" className="lh-1 align-middle">
                          <i className="ri-instagram-line"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg3}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Ameer Hamza</h5>
                    <p className="text-muted mb-0">Frontend Developer</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.facebook.com/profile.php?viewas=100000686899395&id=100009820216040" className="lh-1 align-middle link-primary">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/ameer-hamza-26bb2b221" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="https://www.instagram.com/hamza_rockingbrotherz/"
                          className="lh-1 align-middle link-primary"
                        >
                          <i className="ri-instagram-line"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg4}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Muhammad Bilal</h5>
                    <p className="text-muted mb-0">Software Engineer</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.facebook.com/profile.php?id=100008296036375" className="lh-1 align-middle link-primary">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/muhammadbilal-gujrat/" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="https://www.instagram.com/bilal92.grt/"
                          className="lh-1 align-middle link-primary">
                          <i className="ri-instagram-line"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="https://twitter.com/Bilal45Grt" className="lh-1 align-middle link-primary">
                          <i className="ri-twitter-fill"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg6}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Ghous Hanif Dar</h5>
                    <p className="text-muted mb-0">React Js Developer</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.facebook.com/GhousDar/" className="lh-1 align-middle link-primary">
                          <i className="ri-facebook-fill"></i>
                        </Link>
                      </li>

                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/ghousdar/" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link to="https://www.instagram.com/ghous_dar/" className="lh-1 align-middle link-primary">
                          <i className="ri-instagram-line"></i>
                        </Link>
                      </li>


                      <li className="list-inline-item">
                        <Link to="https://twitter.com/GhousHanif28727" className="lh-1 align-middle link-primary">
                          <i className="ri-twitter-fill"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
              <Col md={6} lg={4}>
                <Card>
                  <CardBody className="py-4 text-center">
                    <div className="mx-auto avatar-md mb-3">
                      <img
                        src={teamImg5}
                        alt=""
                        className="img-fluid rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-1">Khuzaima Ibne Saeed</h5>
                    <p className="text-muted mb-0">UI/UX Designer</p>
                  </CardBody>
                  <div className="card-footer text-center">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item">
                        <Link to="https://www.linkedin.com/in/khuzima-ibne-saeed-03b63a275/" className="lh-1 align-middle link-primary">
                          <i className="ri-linkedin-fill"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurTeam;
